import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai"

const info = {
  firstName: "Peter",
  lastName: "Khoury",
  email: "peterkhoury99@gmail.com",
  location: "Dubai, UAE",
  socialLinks: [
    {
      id: "linkedin",
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/peter-el-khoury99/",
      icon: <AiFillLinkedin size={24} />
    },
    {
      id: "instagram",
      name: "Instagram",
      link: "https://www.instagram.com/peter.f.khoury/",
      icon: <AiFillInstagram size={24} />
    }
  ]
}

export default info
