// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

function ArtistCard({ imgSrc, name, classname }) {
  return (
    <div className={`${styles.container} ${classname}`}>
      <img loading="lazy" alt={name} src={imgSrc} className={styles.img} />
      <div className={styles.name}>{name}</div>
    </div>
  )
}

export default ArtistCard
