import info from "./userInfo"

const introduceData = {
  title: (
    <>
      <span>
        {info.firstName} {info.lastName}
      </span>
      , <br />
      Creative Producer <br />& Sound Engineer
    </>
  ),
  subtitle: (
    <>
      I engineer and produce beautifully harmonious sounds, and I love what I
      do. Just pure audio magic!
    </>
  )
}

export default introduceData
