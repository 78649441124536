import React from "react"

// icons
import { RiFolderMusicLine } from "react-icons/ri"

// styles
import styles from "./styles.module.css"

// components
import ProjectCard from "../../components/ProjectCard"
import SectionHeader from "../../components/SectionHeader/SectionHeader"

// data
import projectsData from "../../data/projects"

function Projects({ sectionId }) {
  return (
    <div id={sectionId} className={styles.container}>
      <SectionHeader
        title={projectsData.title}
        badgeLabel="Projects"
        badgeIcon={<RiFolderMusicLine size={20} />}
      />
      <div className={styles.projectsContainer}>
        {projectsData.data.map((project) => (
          <ProjectCard
            title={project.title}
            location={project.location}
            date={project.date}
            summary={project.summary}
            description={project.description}
            youtubeLinks={project.link.youtube}
            instagramLinks={project.link.instagram}
            imgPath={project.imgPath}
          />
        ))}
      </div>
    </div>
  )
}

export default Projects
