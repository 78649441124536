// ** React Import
import React from "react"

// ** Lottie Animations Imports
import Lottie from "react-lottie"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Animations Imports
import loaderAnimation from "../../assets/animations/Line Animation.json"

function Preloader() {
  return (
    <div className={styles.pageContainer}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loaderAnimation
        }}
        height={500}
        width={500}
      />
    </div>
  )
}

export default Preloader
