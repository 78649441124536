// ** Styles Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

function SkillCard({ imgSrc, title }) {
  return (
    <div className={styles.container}>
      <img
        loading="lazy"
        src={imgSrc}
        className={styles.img}
        alt={`skill-card-${title}`}
      />
      <span className={styles.title}>{title}</span>
    </div>
  )
}

export default SkillCard
