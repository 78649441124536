import React from "react"

// styles
import styles from "./button.module.css"

function Button({
  label = "",
  onClick = () => {},
  iconButton = false,
  icon,
  style = {},
  className = "",
  fullWidth = false,
  center = false,
  outerHref = null,
  variant // null - link - inverted
}) {
  const OuterHerfNode = outerHref ? "a" : React.Fragment

  return (
    <OuterHerfNode
      href={outerHref ? outerHref : undefined}
      target={outerHref ? "_blank" : null}
      // rel={outerHref ? "noopener noreferrer" : null}
    >
      <button
        onClick={onClick}
        className={`${styles.button} ${iconButton ? styles.iconButton : ""} ${
          variant === "link" ? styles.linkButton : null
        } ${variant === "inverted" ? styles.inverted : null} ${className}`}
        style={{
          margin: center && "0 auto",
          width: fullWidth && "100%",
          ...style
        }}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        {!iconButton && (
          <span className={styles.label}>{label?.toUpperCase()}</span>
        )}
      </button>
    </OuterHerfNode>
  )
}

export default Button
