import React from "react"

// styles
import styles from "./sectionHeader.module.css"

// components
import Badge from "../Badge/Badge"

function SectionHeader({ title, badgeIcon, badgeLabel }) {
  return (
    <div className={styles.container}>
      {(badgeIcon || badgeLabel) && (
        <Badge className={styles.badge} icon={badgeIcon} label={badgeLabel} />
      )}

      <h2 className={styles.title}>{title}</h2>
    </div>
  )
}

export default SectionHeader
