// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Icons Imports
import { MdOutlineChat } from "react-icons/md"

// ** Data Imports
import testimonialsData from "../../data/testimonials"

// ** Third Party Imports
import Slider from "react-slick"

// ** Component Imports
import TestimonialCard from "../../components/TestimonialCard"
import SectionHeader from "../../components/SectionHeader/SectionHeader"

function Testimonials({ sectionId }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000
  }
  return (
    <div className={styles.sectionContainer} id={sectionId}>
      <SectionHeader
        title={testimonialsData.title}
        badgeLabel="Testimonials"
        badgeIcon={<MdOutlineChat size={19} />}
      />

      <Slider {...settings}>
        {testimonialsData.data.map((testimonial) => (
          <TestimonialCard
            key={testimonial.id}
            name={testimonial.name}
            title={testimonial.title}
            imgSrc={testimonial.img}
            text={testimonial.text}
          />
        ))}
      </Slider>
    </div>
  )
}

export default Testimonials
