// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

function CertificateCard({ title, img, from, renderImg = () => {} }) {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <span className={styles.from}>{from}</span>
      {/* <img loading="lazy" alt={`${title}-certfication`} className={styles.img} src={imgSrc} /> */}
      <span className={styles.img}>{img}</span>
    </div>
  )
}

export default CertificateCard
