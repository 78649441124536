import React from "react"

// styles
// import styles from "./experienceTimeline.module.css"

// components
import Timeline from "../Timeline/Timeline"

function ExperienceTimeline({ data = [] }) {
  return (
    <div>
      {data.map((experience) => (
        <Timeline period={experience?.time} positions={experience?.positions} />
      ))}
    </div>
  )
}

export default ExperienceTimeline
