const educationData = [
  {
    time: (
      <>
        <div>
          <span
            style={{
              fontSize: 24,
              fontStyle: "italic"
              //   color: "var(--light-gray-color)"
            }}
          >
            Bachelor of audio engineering and studio production
          </span>
        </div>
        2018 - 2020,{" "}
        <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
          SAE INSTITUTE DUBAI
        </span>
      </>
    ),
    positions: [
      {
        title: (
          <span style={{ fontSize: 16 }}>
            Microphones and Signal processing
          </span>
        )
      },
      {
        title: (
          <span style={{ fontSize: 16 }}>
            Principles of sounds and acoustics Audio engineering
          </span>
        )
      },
      {
        title: (
          <span style={{ fontSize: 16 }}>Live sound engineering FOH - BOH</span>
        )
      },
      {
        title: (
          <span style={{ fontSize: 16 }}>
            Studio, Audio, and Post production
          </span>
        )
      },
      {
        title: (
          <span style={{ fontSize: 16 }}>Radio and Broadcast essentials</span>
        )
      },
      {
        title: <span style={{ font6ize: 18 }}>Music Business & Marketing</span>
      }
    ]
  }
  // {
  //   time: (
  //     <>
  //       <div>
  //         <span
  //           style={{
  //             fontSize: 24,
  //             fontStyle: "italic"
  //             //   color: "var(--light-gray-color)"
  //           }}
  //         >
  //           Ableton live workshop with NRJ radio manager Marc Haddad
  //         </span>
  //       </div>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         SAE INSTITUTE DUBAI
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: (
  //         <img loading="lazy"
  //           src={ablteon}
  //           alt="ablteon"
  //           style={{ objectFit: "contain", width: 250 }}
  //         />
  //       )
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       <div>
  //         <span
  //           style={{
  //             fontSize: 24,
  //             fontStyle: "italic"
  //             //   color: "var(--light-gray-color)"
  //           }}
  //         >
  //           Protools workshop with Tamer Hassan AudioVision
  //         </span>
  //       </div>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         SAE INSTITUTE DUBAI
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: (
  //         <img loading="lazy"
  //           src={protools}
  //           alt="protools"
  //           style={{ objectFit: "contain", width: 250 }}
  //         />
  //       )
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       <div>
  //         <span
  //           style={{
  //             fontSize: 24,
  //             fontStyle: "italic"
  //             //   color: "var(--light-gray-color)"
  //           }}
  //         >
  //           Shure wired microphones workshop with Barry Kassab
  //         </span>
  //       </div>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         SAE INSTITUTE DUBAI
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: (
  //         <img loading="lazy"
  //           src={shure}
  //           alt="shure"
  //           style={{ objectFit: "contain", width: 250 }}
  //         />
  //       )
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       <div>
  //         <span
  //           style={{
  //             fontSize: 24,
  //             fontStyle: "italic"
  //             //   color: "var(--light-gray-color)"
  //           }}
  //         >
  //           Yamaha Live Sound Workshop with Thomson Pure Music{" "}
  //         </span>
  //       </div>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         SAE INSTITUTE DUBAI
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: (
  //         <img loading="lazy"
  //           src={yamaha}
  //           alt="yamaha"
  //           style={{ objectFit: "contain", width: 250 }}
  //         />
  //       )
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       <div>
  //         <span
  //           style={{
  //             fontSize: 24,
  //             fontStyle: "italic"
  //             //   color: "var(--light-gray-color)"
  //           }}
  //         >
  //           Shure Mic Lab with Barry Kassab
  //         </span>
  //       </div>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         SAE INSTITUTE DUBAI
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: (
  //         <img loading="lazy"
  //           src={shure}
  //           alt="shure"
  //           style={{ objectFit: "contain", width: 250 }}
  //         />
  //       )
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       <div>
  //         <span
  //           style={{
  //             fontSize: 24,
  //             fontStyle: "italic"
  //             //   color: "var(--light-gray-color)"
  //           }}
  //         >
  //           Stage management workshop EMDI Institute of Media and Communication
  //         </span>
  //       </div>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         SAE INSTITUTE DUBAI
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title:
  //         // <img loading="lazy"
  //         //   src={protools}
  //         //   alt="protools"
  //         //   style={{ objectFit: "contain",width: 250 }}
  //         // />
  //         null
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       <div>
  //         <span
  //           style={{
  //             fontSize: 24,
  //             fontStyle: "italic"
  //             //   color: "var(--light-gray-color)"
  //           }}
  //         >
  //           CPR/First Aider With Dubai Corporation For Ambulance Services
  //         </span>
  //       </div>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         SAE INSTITUTE DUBAI
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: (
  //         <img loading="lazy"
  //           src={ambulance}
  //           alt="protools"
  //           style={{ objectFit: "contain", width: 250 }}
  //         />
  //       )
  //     }
  //   ]
  // }
]

export default educationData
