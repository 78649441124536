const aboutData = {
  title: (
    <>
      Every great song begin with an even{" "}
      <span style={{ color: "var(--primary-color)" }}>better story</span>
    </>
  ),
  description: (
    <>
      I've dedicated myself to convincing people that human potential knows no
      bounds and that pursuing one's passion, like music, can create positive
      change in the world. My journey took me to the UAE at 18, turning my love
      for music into a career, eventually leading me to work with names I never
      thought I’ll ever meet such as Beyonce, Chris martin, AR Rahman Ziad
      Rahbani and more. Collaborating with these industry luminaries shaped my
      exceptional skills and work ethics. These experiences molded me into the
      person I am today, equipped with the skills, connections and expertise to
      thrive in the industry. I aspire to inspire the next generation, showing
      them the potential, they hold to make a difference in the music scene in
      the region.
    </>
  )
}

export default aboutData
