import React from "react"

// styles
import styles from "./introduce.module.css"

// data
import introduceData from "../../data/introduce"

// icons
import { HiOutlineHome } from "react-icons/hi"

// helpers
import { smoothScrollToSection } from "../../helpers/scrollHelpers"

// components
import Badge from "../../components/Badge/Badge"
import MyProjectsButton from "../../components/MyProjectsButton/MyProjectsButton"

function Introduce({ sectionId }) {
  // ** Handlers
  const handleMyProjectsButtonClick = () => {
    smoothScrollToSection("scroll-to-projects")
  }

  return (
    <div className={styles.container} id={sectionId}>
      <Badge
        label="Introduce"
        icon={<HiOutlineHome />}
        className={styles.badge}
      />
      <h1 className={styles.title}>{introduceData.title}</h1>
      <MyProjectsButton
        className={styles.projectsButton}
        onClick={handleMyProjectsButtonClick}
      />
      <div className={styles.introduceStatsContainer}>
        <div>
          <span className={styles.statsNumber}>7 +</span>
          <span className={styles.statsTitle}>YEARS OF EXPERIENCE</span>
        </div>
        <div>
          <span className={styles.statsNumber}>60 +</span>
          <span className={styles.statsTitle}>
            PROJECTS COMPLETED IN THE MIDDLE EAST
          </span>
        </div>
      </div>
    </div>
  )
}

export default Introduce
