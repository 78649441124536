const projectsData = {
  title: (
    <>
      Featured <span style={{ color: "var(--primary-color)" }}>Projects</span>
    </>
  ),
  data: [
    {
      title: "Expo City Dubai – Firdaus Studio by A.R. RAHMAN",
      summary: "Sound Engineer and creative lead",
      date: "February 2023 – Present",
      location: "Dubai, United Arab Emirates",
      imgPath: require("../assets/projects/expo.JPG"),
      description: (
        <span style={{ fontWeight: 300, fontSize: 20 }}>
          As my journey progressed within Firdaus Studio, in tandem with Firdaus
          Orchestra, I assumed pivotal responsibilities in a multitude of
          international productions and initiatives. Noteworthy among these
          engagements were my roles as Assistant Recording Engineer for Simon
          Rhodes at Abbey Road Studios, Assistant Stage Manager for the 2023
          BEYONCÉ Atlantis Royal Show, and Music Assistant to Leon Lacey,
          Beyoncé's Orchestral Conductor. Additionally, I held the position of
          Stage Manager and Creative Lead for both Firdaus Studio & Orchestra
          and Studio during the AA Live Show at COCA-COLA Arena Dubai, among
          other prominent projects.
        </span>
      ),
      link: {
        youtube: [
          "https://www.youtube.com/embed/Z0VDI8xRX84",
          "https://www.youtube.com/embed/LNYwqapTS4A",
          "https://www.youtube.com/embed/be9QHF-KRvc"
        ],
        instagram: [
          "https://www.instagram.com/reel/CsqBRK9MICa",
          "https://www.instagram.com/reel/CvfSTSYp2VM",
          "https://www.instagram.com/reel/CuJpoftMiwM"
        ]
      }
    },
    {
      title: "Expo 2020 Dubai",
      summary: "Sound Engineer and creative lead (Studio Production)",
      date: "September 2021 – January 2023",
      location: "Dubai, United Arab Emirates",
      imgPath: require("../assets/projects/expo-peter.jpeg"),
      description: (
        <span style={{ fontWeight: 300, fontSize: 20 }}>
          -Sound Engineer for the musical production "WHY WHY WHY," showcased at
          EXPO2020's Al Wasl Plaza, encompassing roles in Recording, Editing,
          and Atmos Mixing.
          <br />
          -Fulfilling the roles of Recording and Mix Engineer for Firdaus
          Orchestra's Live Performances at EXPO2020.
          <br />
          -Serving as the esteemed Sound Engineer for the esteemed composer AR
          RAHMAN.
          <br />
          -Exercising the role of Lead Orchestral Recording Engineer for the
          EXPO2020 Closing Ceremony, a production orchestrated by the eminent
          RON COLVARD.
          <br />
          -Spearheading the position of Lead Engineer across various recording
          sessions, notable examples include collaborations with Ben&Ben, Sony
          Music, Sixieme Son, AlDar, Netflix, among others.
          <br />
          -SDemonstrating proficiency as the Creative Lead for social media
          initiatives and collaborative endeavours within the studio
          environment.
        </span>
      ),
      link: {
        youtube: [
          "https://www.youtube.com/embed/cpEY6ETOrYo",
          "https://www.youtube.com/embed/k2oRqj_NO7Q",
          "https://www.youtube.com/embed/2KRw9ydwrdE",
          "https://www.youtube.com/embed/MILrbWX2XUk",
          "https://www.youtube.com/embed/WTTqeKPR1Bs",
          "https://www.youtube.com/embed/1a1-8wLAEfU",
          // "https://www.youtube.com/embed/UxHbPSeWGWw",
          "https://www.youtube.com/embed/8Me8EPKp4cE",
          "https://www.youtube.com/embed/8Xza__N_pzA"
          // "https://www.youtube.com/embed/olx7BRmV0ig",
          // "https://www.youtube.com/embed/i7e4CdS5gtk"
        ],
        instagram: ["https://www.instagram.com/reel/CqDxlYujUsC"]
      }
    },
    {
      title: "MusicHall by Elefteriades",
      summary: "Assistant Audio Engineer / Stage Manager",
      date: "October 2019 - Present (2 years 9 months)",
      location: "Dubai, United Arab Emirates",
      imgPath: require("../assets/projects/mh.JPG"),
      description: (
        <span style={{ fontWeight: 300, fontSize: 20 }}>
          - Assumed the role of Assistant Live Sound Engineer, catering to
          diverse bands and large-scale orchestra performances, orchestrating
          seamless audio execution.
          <br />
          - Expertly managed the stage environment in the capacity of a Stage
          Manager, ensuring smooth flow of performances and optimal
          coordination.
          <br />
          -Sound Engineer For AR RAHMAN
          <br />
          - Proficiently undertook responsibilities encompassing patching, stage
          setup, and comprehensive show production, enhancing the overall
          production quality.
          <br />- Undertook meticulous equipment maintenance duties, including
          the upkeep of essential components such as the Soundcraft Vi6000 live
          desk, Pioneer DJ decks, microphones, and in-ear monitors, among
          others.
        </span>
      ),
      link: {
        youtube: ["https://www.youtube.com/embed/kwA8Wx3ZbA8"],
        instagram: []
      }
    },
    {
      title: "Jean Marie Riachi Studio",
      summary: "Sound Engineer/Studio Operater",
      date: "October 2019 - Present (2 years 9 months)",
      location: "Mtayleb, Lebanon ",
      imgPath: require("../assets/projects/JMR 1.JPG"),
      description: (
        <span>
          -Studio Operator <br />
          -Protools Operator
        </span>
      ),
      link: {
        youtube: [
          "https://www.youtube.com/embed/IiPXjuMvDUg",
          "https://www.youtube.com/embed/VUsSyQSaJLo",
          "https://www.youtube.com/embed/91Dma2nvEGg"
        ],
        instagram: []
      }
    },
    {
      title: "ZIAD EL RAHBANI Studio (Notta Studio)",
      summary: "Studio Sound Engineer",
      date: "October 2020 - June 2021 (9 months)",
      location: "Lebanon",
      imgPath: require("../assets/projects/ziad.JPG"),
      description: (
        <span>
          - Proficiently organized and executed studio and session setups,
          meticulously handling tasks such as creating input lists, preparing
          mic lists, and strategic mic positioning for optimal recording
          conditions. <br />
          - Contributed as a Sound and Recording Engineer for a diverse array of
          esteemed artists including Carole Samaha, Ziad Rahbani etc… <br />-
          Skillfully operated advanced tools including ProTools and the AMEK
          Angela 32 analog desk, ensuring precise control over recording and
          production processes.
        </span>
      ),
      link: {
        youtube: [
          "https://www.youtube.com/embed/ys0rkPH4-HU",
          "https://www.youtube.com/embed/sncojSPZsR0",
          "https://www.youtube.com/embed/xiAs_E2rDH8",
          "https://www.youtube.com/embed/jEH-e9Jj8wk"
        ],
        instagram: []
      }
    },
    {
      title: "Eddy Jazra audio production ",
      summary: "Sound Engineer/Studio Producer",
      date: "August 2020 - June 2021 (11 months)",
      location: "Lebanon",
      imgPath: require("../assets/projects/eddy.jpg"),
      description: (
        <span>
          <strong>**STUDIO**</strong>
          <br />
          - Conducted post-production and sound design for an informative video
          featuring Janssen, employing advanced techniques to enhance
          audiovisual content. <br />
          - Managed the complete recording, editing, and mixing process for
          voice-overs (VOs), ensuring top-notch audio quality and seamless
          integration with visual elements.
          <br />
          - Directed recording and editing sessions for prominent artists such
          as Carole Samaha, Tania Kassis, and Mayssa Karaa (Berklee), showcasing
          a dedication to sonic excellence. <br />
          - Applied meticulous editing and skillful mixing to elevate the audio
          quality of LBCI Christmas concerts in 2020, featuring artists Mayssa
          Karaa and Edgard Aoun. <br />
          <br />
          <strong>**LIVE**</strong>
          <br />
          - Spearheaded on-set setup and live recording for Mayssa Karaa's
          Christmas concert in 2020, broadcasted on LBCI, ensuring impeccable
          sound capture and production.
          <br />- Assumed responsibility for on-set setup and live recording
          during Edgard Aoun's Christmas concert in 2020, aired on LBCI,
          contributing to the flawless execution of the event.
        </span>
      ),
      link: {
        youtube: [
          "https://www.youtube.com/embed/0h0TMxV1urM",
          "https://www.youtube.com/embed/9cMdapPm8HQ",
          "https://www.youtube.com/embed/OjQyjDaFfiU",
          "https://www.youtube.com/embed/Ovaw3-QyAFs"
        ],
        instagram: []
      }
    },
    // {
    //   title: "Music or Media Holding",
    //   summary: "Audio Engineer & Radio producer",
    //   date: "November 2017 - April 2018 (6 months)",
    //   location: "Lebanon",
    //   imgPath: require("../assets/projects/expo.jpeg"),
    //   description: null,
    //   link: {
    //     youtube: [],
    //     instagram: []
    //   }
    // },
    {
      title: "NRJ Radio",
      summary: null,
      date: "November 2017 to April 2018",
      location: "Lebanon",
      imgPath: require("../assets/projects/NRJ.JPG"),
      description: (
        <span>
          <a href="http://www.nrjlebanon.com">NRJ Radio</a>
          <br />
          <br />
          - Demonstrated expertise in recording and producing daily news
          segments and radio shows, notably including "NRJ TOP 20," "EURO
          HOT20," "NRJ EXTRAVADANCE," and the "LEBANESE TOP 20," ensuring a
          consistently engaging and high-quality auditory experience.
          <br />
          - Skillfully managed the recording and production of radio
          advertisements and promotional content, contributing to effective
          marketing and audience engagement strategies. <br />
          - Assumed the crucial responsibility of revising daily music playlists
          for radio broadcasts, adeptly curating content to maintain a dynamic
          and appealing on-air presence. <br />
          - Pioneered the creation of new segments and specialised shows
          tailored for special events and weekend broadcasts, enhancing the
          station's programming diversity and audience engagement. <br />
          - Orchestrated efficient show management and scheduling, optimizing
          the seamless execution of radio programs and maintaining a
          well-structured broadcasting lineup. <br />
        </span>
      ),
      link: {
        youtube: [],
        instagram: []
      }
    },
    {
      title: "NOSTALGIE RADIO",
      summary: null,
      date: null,
      location: "Lebanon",
      imgPath: require("../assets/projects/nostalgie.png"),
      description: (
        <span>
          <a href="https://www.nostalgie.com.lb">NOSTALGIE Radio</a>
          <br />
          <br />
          - Undertook the recording and proficient editing of the daily French
          radio program "HAPPY DAYS," ensuring a polished and captivating
          auditory experience for the audience.
          <br />- Conducted diligent revision and curation of the radio's daily
          music playlists, finely tuning content selection to sustain an
          appealing and vibrant on-air presentation.
        </span>
      ),
      link: {
        youtube: [],
        instagram: []
      }
    },
    {
      title: "AGHANI AGHANI RADIO AND TV STATION",
      summary: null,
      date: null,
      location: "Lebanon",
      imgPath: require("../assets/projects/aghani.jpeg"),
      description: (
        <span>
          <a href="https://aghaniaghani.com">AGHANI AGHANI</a>
          <br />
          <br />
          - Spearheaded the comprehensive recording and production processes for
          both the "Aghani Aghani News" radio show and the "Aghani Aghani News"
          TV show, showcasing a keen attention to detail and a commitment to
          delivering captivating content.
          <br />- Directed the creation and production of engaging radio
          advertisements, effectively contributing to promotional campaigns and
          audience outreach. <br />
          - Methodically revised and curated the daily music playlists for radio
          broadcasts, ensuring a dynamic and appealing auditory experience for
          listeners. <br />
        </span>
      ),
      link: {
        youtube: [],
        instagram: []
      }
    },
    {
      title: "Audiovision Studio",
      summary: "Assistant Audio Engineer",
      date: "September 2017 – April 2018 (8 months)",
      location: "Lebanon",
      imgPath: require("../assets/projects/sv.JPG"),
      description: (
        <span>
          - Proficiently executed the recording, meticulous editing, and
          skillful mixing of various voice-overs (VOs) tailored for diverse
          applications, showcasing a mastery of audio post-production
          techniques. <br />
          - Actively contributed as an Assistant Sound Engineer for the esteemed
          artist Anthony Touma, leveraging technical acumen to enhance live
          sound experiences. <br />
          - Exemplified expertise in the editing and production of multiple
          voice-overs for Lebanese television commercials, contributing to the
          success of impactful advertising campaigns. <br />
        </span>
      ),
      link: {
        youtube: [
          "https://www.youtube.com/embed/TIL6Q8FmA28",
          "https://www.youtube.com/embed/Sw7Tb2elu5M"
        ],
        instagram: []
      }
    }
  ]
}

export default projectsData
