import React from "react"

// ** Icons Imports
import { SiDolby } from "react-icons/si"
import { FaUserTie } from "react-icons/fa"
import { LuPaintbrush } from "react-icons/lu"
import { IoMusicalNotes } from "react-icons/io5"

const specializationsData = {
  title: (
    <>
      My <span style={{ color: "var(--primary-color)" }}>Specializations</span>
    </>
  ),
  specializations: [
    {
      title: "Dolby Atmos Mixing Engineer",
      description:
        "Adept at handling a wide spectrum of soundscapes, from binaural and immersive formats to tailoring audio for home entertainment and cinematic experiences.",
      projectsNumber: null,
      icon: <SiDolby size={30} />
    },
    {
      title: "Creative Producer",
      description:
        "I specialise in crafting and producing a diverse range of musical experiences, spanning from live concerts and events to TV shows and studio productions.",
      projectsNumber: null,
      icon: <LuPaintbrush size={30} />
    },
    {
      title: "Product Manager",
      description:
        "I specialise in customising marketing concepts, strategies, and collaborations tailored exclusively for musical experiences, encompassing live performance concerts, song releases, album launches, and TV show premieres, all within the realm of the music and entertainment industry.",
      projectsNumber: null,
      icon: <FaUserTie size={30} color="red" />
    },
    {
      title: "Sound Engineer",
      description:
        "Proficient in both live sound engineering and multitrack recording for various settings, including orchestras and individual artists, along with expertise in studio sound engineering encompassing mixing and recording.",
      projectsNumber: null,
      icon: <IoMusicalNotes size={30} />
    }
  ]
}
export default specializationsData
