import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import Preloader from "./components/Preloader"

const AppLoader = () => {
  const [appLoaded, setAppLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAppLoaded(true)
    }, 3000)
  }, [])

  return appLoaded ? <App /> : <Preloader />
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<AppLoader />)
