import React from "react"

// ** Styles Imports
// import styles from "./experience.module.css"

// ** Data Imports
import educationData from "../../data/education"
import experienceData from "../../data/experience"

// ** Icons Imports
import { BiBriefcase } from "react-icons/bi"

// ** Third Party Imports
import Slider from "react-slick"

// ** Component Imports
import SectionHeader from "../../components/SectionHeader/SectionHeader"
import ExperienceTimeline from "../../components/ExperienceTimeline/ExperienceTimeline"
import CertificateCard from "../../components/CertificateCard"
import certificationsData from "../../data/certifications"

function Experience({ sectionId }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000
  }
  return (
    <div id={sectionId}>
      {/* Experience */}
      <SectionHeader
        title={<>Experience</>}
        badgeIcon={<BiBriefcase />}
        badgeLabel="Resume"
      />
      <ExperienceTimeline data={experienceData} />

      {/* Education */}
      <SectionHeader
        title={
          <>
            Education &{" "}
            <span style={{ color: "var(--primary-color" }}>Certification</span>
          </>
        }
      />
      <ExperienceTimeline data={educationData} />

      {/* Certifications */}
      <Slider {...settings} style={{ marginTop: 100 }}>
        {certificationsData.map((el) => (
          <CertificateCard title={el.title} from={el.from} img={el.img} />
        ))}
      </Slider>
    </div>
  )
}

export default Experience
