import React from "react"

// styles
import styles from "./Badge.module.css"

function Badge({ label = "", iconBadge = false, icon, className }) {
  return (
    <div
      className={`${styles.badge} ${
        iconBadge ? styles.iconBadge : ""
      } ${className}`}
    >
      {icon && <span className={`${styles.icon}`}>{icon}</span>}
      {!iconBadge && (
        <span
          className={styles.label}
          style={{ color: "var(--text-color)", fontSize: 11 }}
        >
          {label?.toUpperCase()}
        </span>
      )}
    </div>
  )
}

export default Badge
