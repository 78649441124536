const testimonialsData = {
  title: (
    <>
      Trusted By{" "}
      <span style={{ color: "var(--primary-color)" }}>
        Industry Professionals
      </span>
    </>
  ),
  data: [
    {
      id: 1,
      name: "AR Rahman",
      title: (
        <>
          Academy Award Winner{" "}
          <span style={{ color: "var(--primary-color)" }}>Music Composer</span>
        </>
      ),
      text: "Peter, is an invaluable asset to any project or company. His unwavering passion, insatiable thirst for knowledge, and unwavering commitment to industry standards make him the ideal professional that any industry would be fortunate to have. Moreover, Peter's remarkable versatility in various music styles and his comprehensive understanding of the music industry are skills that typically take years of experience to develop. With Peter on board, success and excellence are guaranteed.",
      img: require("../assets/testimonials/1.webp")
    },
    {
      id: 2,
      name: "Leon Lacy",
      title: (
        <>
          Film Scoring/ Composer{" "}
          <span style={{ color: "var(--primary-color)" }}>
            Marvel, Beyoncé, Wyclef, Janet, Iyanla Vanzant, LA Philharmonic,
            Disney
          </span>
        </>
      ),
      text: "In January 2023 I had the honor of working with a production Genius Mr. Peter Khoury. While I was working as the Orchestra Composer and Conductor in Dubai for Beyonce I was very fortunate to meet and work with Peter Khoury! His great personality and professional attitude played a major role in production. He was very knowledgeable, accurate and swift with his technical and musical decisions. From the Studio recording sessions with the amazing Fridaus Orchestra to the production rehearsals at the Atlantis Palm with Beyonce, Peter was a true asset to and pleasure to work with! Watching him work made me want to work with Peter again and again, which I will do! I’m trying to figure out how to get him to do some work with me in the United States of America soon!",
      img: require("../assets/testimonials/2.jpeg")
    },
    {
      id: 3,
      name: "Sleiman Demian",
      title: (
        <>
          Lebanese Musician,{" "}
          <span style={{ color: "var(--primary-color)" }}>Music Producer</span>
        </>
      ),
      text: "Peter’s passion for the music industry shines through in everything he does. His genuine love for music and dedication to his craft is evident in the exceptional work he produces. He constantly seeks opportunities to expand his knowledge and stay updated with the latest industry trends, showcasing his commitment to personal and professional growth.",
      img: require("../assets/testimonials/3.jpg")
    },
    {
      id: 4,
      name: "Tarek Majdalani",
      title: (
        <>
          Media Executive / Music Producer, Station Manager{" "}
          <span style={{ color: "var(--primary-color)" }}>MBC GROUP</span>
        </>
      ),
      text: "Throughout my time collaborating with Peter, I have been consistently impressed by his unwavering dedication, creativity, and professionalism. Peter's ability to curate captivating and engaging radio content is truly remarkable. His deep understanding of audience preferences, coupled with his excellent communication skills, enables him to craft compelling shows that resonate with listeners. Moreover, Peter's meticulous attention to detail, impeccable time management, and seamless coordination with the on-air talent and production team ensure smooth operations and a seamless broadcast. His passion for the industry shines through in every aspect of his work, making him an invaluable asset to NRJ Lebanon and a true standout in the field of radio production.",
      img: require("../assets/testimonials/4.jpg")
    },
    {
      id: 5,
      name: "Elia Mssawir",
      title: (
        <>
          CEO of{" "}
          <span style={{ color: "var(--primary-color)" }}>GXR Records</span>
        </>
      ),
      text: "A talented music engineer who exceeded my expectations. His vast knowledge of music production, attention to detail, and ability to guide us through the recording process all contributed to high-quality recordings. Despite working under tight deadlines and pressure, Peter maintained a professional and friendly demeanour, always going above and beyond to ensure our satisfaction.",
      img: require("../assets/testimonials/5.jpeg")
    },
    {
      id: 6,
      name: "Mayssa Karaa",
      title: (
        <>
          GRAMMY award-nominated singer songwriter Artistic Director at Berklee
          Abu Dhabi & Creative Lead at{" "}
          <span style={{ color: "var(--primary-color)" }}>Expo 2020 Dubai</span>
        </>
      ),
      text: "Peter is always dedicated to music and creative performance and acts quickly to correct any issues. He achieves this by developing his own intervention, cooperating with professionals, studying, and seeking guidance. He quickly drew everyone's attention with his youthful vigor, skill, and expertise, and he rose to prominence as a promising individual in the industry.",
      img: require("../assets/testimonials/6.jpg")
    },
    {
      id: 7,
      name: "Barry Kassab",
      title: (
        <>
          Associate manager, Market Development at{" "}
          <span style={{ color: "var(--primary-color)" }}>Shure MEA</span>
        </>
      ),
      text: "Peter possesses a remarkable level of Flexibility when it comes to the music industry and his approach to concepts and ideas. His adaptability in navigating different type of projects and his expertise in various aspects of the music industry is truly exceptional. This level of proficiency is typically acquired by seasoned industry professionals after years of experience in the industry. Peter's mastery in this area is truly remarkable and sets him apart as a highly skilled professional.",
      img: require("../assets/testimonials/7.jpeg")
    }
  ]
}

export default testimonialsData
