import React from "react"

// styles
import styles from "./specializations.module.css"

// data
import specializationsData from "../../data/specializations"

// icons
import { LuHelpingHand } from "react-icons/lu"

// components
import SectionHeader from "../../components/SectionHeader/SectionHeader"
import SpecializationCard from "../../components/SpecializationCard/SpecializationCard"

function Specializations({ sectionId }) {
  return (
    <div id={sectionId}>
      <SectionHeader
        title={specializationsData.title}
        badgeLabel="Services"
        badgeIcon={<LuHelpingHand size={19} />}
      />

      <div className={styles.cardsContainer}>
        {specializationsData.specializations.map((specialization) => (
          <SpecializationCard
            title={specialization.title}
            description={specialization.description}
            projectsNumber={specialization.projectsNumber}
            icon={specialization.icon}
          />
        ))}
      </div>
    </div>
  )
}

export default Specializations
