// ** React Imports
import React, { useEffect, useState } from "react"

// ** Style Imports
import styles from "./styles.module.css"

// ** Data Imports
import artistsData from "../../../data/artists"

// ** Component Imports
import Slider from "react-slick"
import ArtistCard from "../../../components/ArtistCard"

function WorkedWithArtists({ sectionId }) {
  // ** React Slick
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000
  }

  // ** States
  const [artistsSubArray, setArtistsSubArray] = useState([])

  // ** Handlers
  function createSubArrays(inputArray) {
    const subArrays = []
    const chunkSize = 9

    for (let i = 0; i < inputArray.length; i += chunkSize) {
      subArrays.push(inputArray.slice(i, i + chunkSize))
    }

    return subArrays
  }

  useEffect(() => {
    setArtistsSubArray(createSubArrays(artistsData))
  }, [])

  return (
    <div className={styles.container} id={sectionId}>
      <div className={styles.title}>WORK WITH 40+ ARTISTS WORLDWIDE</div>
      <Slider {...settings}>
        {artistsSubArray.map((dataSet) => (
          <div className={styles.imagesContainer}>
            {dataSet.map((artist) => (
              <ArtistCard
                key={artist.id}
                name={artist.name}
                imgSrc={artist.img}
                classname={styles.artistCard}
              />
            ))}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default WorkedWithArtists
