const skillsData = {
  title: (
    <>
      Software<span style={{ color: "var(--primary-color)" }}> Skills</span>
    </>
  ),
  data: [
    {
      title: "Pro Tools",
      iconPath: require("../assets/skills/pro_tool.png")
    },
    {
      title: "Dante Control",
      iconPath: require("../assets/skills/dante.jpg")
    },
    {
      title: "Rekordbox",
      iconPath: require("../assets/skills/rekordbox.png")
    },
    {
      title: "Ableton Live",
      iconPath: require("../assets/skills/ableton_1.png")
    },
    {
      title: "Adobe Premiere",
      iconPath: require("../assets/skills/adobe_premier.png")
    },
    {
      title: "Microsoft 360",
      iconPath: require("../assets/skills/microsoft_office.png")
    },
    {
      title: "Monday.com",
      iconPath: require("../assets/skills/monday.png")
    },
    {
      title: "Macintosh",
      iconPath: require("../assets/skills/macos.png")
    }
  ]
}

export default skillsData
