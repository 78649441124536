const experienceData = [
  {
    time: (
      <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
        United Arab Emirates
      </span>
    ),
    positions: [
      {
        title: "Studio Producer",
        companyName: "EXPO2020"
      },
      {
        title: "Studio Engineer & creative producer",
        companyName: "Firdaus Studio, Expo City Dubai 2019 - 2022"
      },
      {
        title: "Sound Engineer/Stage Manager",
        companyName: "MusicHall, 2018 - 2022"
      },
      {
        title: "Sound Engineer",
        companyName: "Freelancer, 2018 - 2020"
      }
    ]
  },
  {
    time: (
      <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
        Lebanon
      </span>
    ),
    positions: [
      {
        title: "Studio Operator",
        companyName: "Jean Marie Riachi Studios, 2020"
      },
      {
        title: "Sound Engineer",
        companyName: "Ziad El Rahbani"
      },
      {
        title: "Creative Producer",
        companyName: "Eddy Jazra Studios"
      },
      {
        title: "Freelancer DJ",
        companyName: "Private Events, 2020"
      },
      {
        title: "Creative Producer",
        companyName: "Freelancer, 2017 - 2019"
      },
      {
        title: "Event and stage Management",
        companyName: "Freelancer, 2017 - 2018"
      },
      {
        title: "Sound Engineer",
        companyName: "Audio Vision"
      },
      {
        title: "Creative Producer",
        companyName: "NRJ Radio"
      },
      {
        title: "Radio Producer",
        companyName: "Nostalgie Radio"
      },
      {
        title: "Assistant Radio Producer",
        companyName: "Aghani Radio/TV, 2018"
      },
      {
        title: "Music Producer",
        companyName: "Beirut Fashion Week"
      }
    ]
  },
  {
    time: (
      <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
        Saudi Arabia
      </span>
    ),
    positions: [
      {
        title: "Podcast Production & Editing",
        companyName: "Remotely, 2018"
      },
      {
        title: "Entertainment Assistant, Benchmark",
        companyName: "2020 - 2021"
      }
    ]
  }

  // Old Layout
  // {
  //   time: (
  //     <>
  //       2019 - 2022,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         United Arab Emirates
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Assistant sound engineer",
  //       companyName: "MusicHall"
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       2018 - 2022,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         United Arab Emirates
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Sound Engineer",
  //       companyName: "Freelancer"
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       2018 - 2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         United Arab Emirates
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Freelancer DJ",
  //       companyName: "Private Events"
  //     }
  //   ]
  // },

  // // SAUDI ARABIA
  // {
  //   time: (
  //     <>
  //       2020,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         Saudi Arabia
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Podcast Production & Editing",
  //       companyName: "Remotely"
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       2018,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         Saudi Arabia
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Entertainment Assistant, Benchmark",
  //       companyName: "Remotely"
  //     }
  //   ]
  // },

  // // LEBANON
  // {
  //   time: (
  //     <>
  //       2020 - 2021,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         Lebanon
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Sound Engineer",
  //       companyName: "Ziad El Rahbani"
  //     },
  //     {
  //       title: "Sound Engineer",
  //       companyName: "Eddy Jazra Studios"
  //     },
  //     {
  //       title: "Sound Engineer",
  //       companyName: "Freelance"
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       2017 - 2019,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         Lebanon
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Event Management",
  //       companyName: "Freelance"
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       2017 - 2019,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         Lebanon
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Event Management",
  //       companyName: "Freelance"
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       2017 - 2018,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         Lebanon
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Assistant Sound Engineer",
  //       companyName: "Audio Vision"
  //     },
  //     {
  //       title: "Sound Engineer",
  //       companyName: "NRJ Radio"
  //     },
  //     {
  //       title: "Sound Engineer",
  //       companyName: "Nostalgie Radio"
  //     },
  //     {
  //       title: "Assistant Radio Producer",
  //       companyName: "Aghani Radio/TV"
  //     }
  //   ]
  // },
  // {
  //   time: (
  //     <>
  //       2018,{" "}
  //       <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
  //         Lebanon
  //       </span>
  //     </>
  //   ),
  //   positions: [
  //     {
  //       title: "Music Producer",
  //       companyName: "Beirut Fashion Week"
  //     }
  //   ]
  // }
]

export default experienceData
