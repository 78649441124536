import React from "react"

// styles
import styles from "./skills.module.css"

// Data
import skillsData from "../../data/skills"

// icons
import { BiWrench } from "react-icons/bi"

// components
import SkillCard from "../../components/SkillCard"
import SectionHeader from "../../components/SectionHeader/SectionHeader"

function MySkills({ sectionId }) {
  return (
    <div className={styles.container} id={sectionId}>
      <SectionHeader
        title={skillsData.title}
        badgeLabel="My Skills"
        badgeIcon={<BiWrench size={19} />}
      />
      <div className={styles.cardsContainer}>
        {skillsData.data.map((skill) => (
          <SkillCard imgSrc={skill.iconPath} title={skill.title} />
        ))}
      </div>
    </div>
  )
}

export default MySkills
