import React from "react"

// styles
import styles from "./userCard.module.css"

// icons
import { HiOutlineMail } from "react-icons/hi"

// data
import userInfo from "../../data/userInfo"

// assets
import userPortrait from "../../assets/user/portrait.jpg"

// components
import Button from "../Button/Button"

function UserCard({ containerStyle, className }) {
  const handleClick = () => {
    window.open(`mailto:${userInfo.email}`, "_blank")
  }

  return (
    <div
      className={`${styles.card} ${className}`}
      style={{ ...containerStyle }}
    >
      {/* header */}
      <div className={styles.header}>
        <span className={styles.name}>{userInfo.firstName}</span>
        <span className={styles.occupation}>
          Creative Producer
          <br />& Sound Engineer
        </span>
      </div>

      {/* photo */}
      <div className={styles.photoContainer}>
        <img
          loading="lazy"
          src={userPortrait}
          alt={userInfo.firstName}
          className={styles.photo}
        />
      </div>

      <div className={styles.infoContainer}>
        <div>{userInfo.email}</div>
        <div>Based in {userInfo.location}</div>
      </div>

      {/* Social Media Buttons */}
      <div className={styles.socialLinksContainer}>
        {userInfo.socialLinks.map((social) => (
          <Button
            id={social.id}
            key={social.id}
            iconButton
            icon={social.icon}
            outerHref={social.link}
          />
        ))}
      </div>

      <div className={styles.rights}>
        {`© 2022 ${userInfo.firstName} ${userInfo.lastName}. All Rights Reserved`}
      </div>

      <Button
        label="Contact Me"
        icon={<HiOutlineMail />}
        className={styles.button}
        fullWidth
        onClick={handleClick}
      />
    </div>
  )
}

export default UserCard
