// global styles
import "./global-styles/colors.css"
import "./global-styles/common.css"

// ** React Slick Styling Imports
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./global-styles/react-slick.css"
import "./global-styles/scrollbar.css"
import "./global-styles/scroll.css"

// components
import UserCard from "./components/UserCard/UserCard"
import SideNavigation from "./components/SideNavigation/SideNavigation"

// APP styling
import appStyles from "./App.module.css"

// ** Asset Imports
import bgVideo from "./assets/app-background/WEBback2.mp4"

// sections
import About from "./sections/about/About"
import Introduce from "./sections/introduce/Introduce"
import Experience from "./sections/experience/Experience"
import Specializations from "./sections/specializations/Specializations"
import MySkills from "./sections/skills"
import Testimonials from "./sections/testimonials"
import WorkedWithBrands from "./sections/worked-with/brands"
import WorkedWithArtists from "./sections/worked-with/artists"
import Projects from "./sections/projects"

function App() {
  return (
    <div className={appStyles.mainContainer}>
      {/* VIDEO BACKGROUND */}
      <video
        src={bgVideo}
        autoPlay
        loop
        muted
        style={{
          objectFit: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -99999999,
          filter: "brightness(0.2)"
        }}
      />

      <div className={appStyles.leftContainer}>
        <UserCard className={appStyles.userCard} />
      </div>
      <div className={appStyles.rightContainer}>
        <UserCard className={appStyles.userCardMobile} />

        <SideNavigation className={appStyles.sideNavigation} />

        <Introduce sectionId="scroll-to-introduce" />

        <About sectionId="scroll-to-about" />

        <Experience sectionId="scroll-to-experience" />

        <Specializations sectionId="scroll-to-specializations" />

        <MySkills sectionId="scroll-to-skills" />

        <Testimonials sectionId="scroll-to-testimonials" />

        <WorkedWithBrands sectionId="scroll-to-worked-with-brands" />

        <WorkedWithArtists sectionId="scroll-to-worked-with-artists" />

        <Projects sectionId="scroll-to-projects" />
      </div>
    </div>
  )
}

export default App
