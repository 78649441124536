import React from "react"

// styles
import styles from "./myProjectsButton.module.css"

// icons
import { BsArrowDown } from "react-icons/bs"

// assets
import MyProjectsImage from "./assets/round-text.png"

function MyProjectsButton({ style, className, onClick, rest }) {
  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={onClick}
      style={{ ...style }}
      {...rest}
    >
      <img loading="lazy" src={MyProjectsImage} alt="My projects" />
      <BsArrowDown className={styles.arrow} />
    </button>
  )
}

export default MyProjectsButton
