import React from "react"

// styles
import styles from "./specializationCard.module.css"

function SpecializationCard({ title, description, projectsNumber, icon }) {
  return (
    <div className={styles.card}>
      <div
        className={styles.header}
        style={{
          marginBottom: projectsNumber && "40px"
        }}
      >
        <div>
          <h3>{title}</h3>
          {description && <p>{description}</p>}
        </div>
        <div className={styles.iconContainer}>{icon}</div>
      </div>

      {projectsNumber && (
        <span className={styles.projectsSpan}>{projectsNumber} PROJECTS</span>
      )}
    </div>
  )
}

export default SpecializationCard
