import React from "react"

// styles
import styles from "./timeline.module.css"

function Timeline({ period, positions = [] }) {
  return (
    <div className={styles.container}>
      {/* ------- LINE ------- */}
      <div className={styles.lineContainer}>
        <div className={styles.lineDot}></div>
        <div className={styles.line}></div>
      </div>
      {/* ---------------------- */}

      <div className={styles.period}>{period}</div>

      {positions?.map((position) => (
        <div className={styles.positionContainer}>
          <h3>{position.title}</h3>
          {position.companyName && <span>{position.companyName}</span>}
        </div>
      ))}
    </div>
  )
}

export default Timeline
