// ** Assets Imports
import ablteon from "../assets/skills/ableton_1.png"
import protools from "../assets/skills/pro_tool.png"
import ambulance from "../assets/certifications/ambulance.png"
import shure from "../assets/certifications/shure.png"
import yamaha from "../assets/certifications/yamaha.png"
import edmi from "../assets/certifications/emdi.png"
import dolby from "../assets/certifications/dolby.png"

const certificationsData = [
  {
    title: "Dolby Atmos Mixing certification",
    from: (
      <>
        <span style={{ color: "var(--primary-color)" }}>Dolby</span>
      </>
    ),
    img: (
      <img
        alt="dolby"
        loading="lazy"
        src={dolby}
        style={{ height: 200, margin: "30px auto 10px auto" }}
      />
    )
  },
  {
    title: "Ableton live workshop",
    from: (
      <>
        Marc Haddad -{" "}
        <span style={{ color: "var(--primary-color)" }}>NRJ radio manager</span>
      </>
    ),
    img: (
      <img
        alt="ableton"
        loading="lazy"
        src={ablteon}
        style={{ height: 200, margin: "30px auto 10px auto" }}
      />
    )
  },
  {
    title: "Protools workshop",
    from: (
      <>
        Tamer Hassan -{" "}
        <span style={{ color: "var(--primary-color)" }}>AudioVision</span>
      </>
    ),
    img: (
      <img
        alt="protools"
        loading="lazy"
        src={protools}
        style={{ height: 200, margin: "30px auto 10px auto" }}
      />
    )
  },
  {
    title: "Shure wired microphones workshop ",
    from: (
      <>
        Barry Kassab -{" "}
        <span style={{ color: "var(--primary-color)" }}>Shure</span>
      </>
    ),
    img: (
      <img
        alt="shure"
        loading="lazy"
        src={shure}
        style={{ height: 65, margin: "30px auto 10px auto" }}
      />
    )
  },
  {
    title: "Yamaha Live Sound Workshop",
    from: (
      <>
        Thomson -{" "}
        <span style={{ color: "var(--primary-color)" }}>Pure Music</span>
      </>
    ),
    img: (
      <img
        alt="yamaha"
        loading="lazy"
        src={yamaha}
        style={{ height: 65, margin: "30px auto 10px auto" }}
      />
    )
  },
  {
    title: "Shure Mic Lab",
    from: (
      <>
        Barry Kassab -{" "}
        <span style={{ color: "var(--primary-color)" }}>Shure</span>
      </>
    ),
    img: (
      <img
        alt="shure"
        loading="lazy"
        src={shure}
        style={{ height: 65, margin: "30px auto 10px auto" }}
      />
    )
  },
  {
    title: "Stage management workshop",
    from: (
      <>
        EMDI -{" "}
        <span style={{ color: "var(--primary-color)" }}>
          Institute of Media and Communication
        </span>
      </>
    ),
    img: (
      <img
        alt="edmi"
        loading="lazy"
        src={edmi}
        style={{ height: 100, margin: "30px auto 10px auto" }}
      />
    )
  },
  {
    title: "CPR/First Aider With Dubai Corporation For Ambulance Services",
    from: (
      <>
        <span style={{ color: "var(--primary-color)" }}>
          Dubai Corporation For Ambulance Services
        </span>
      </>
    ),
    img: (
      <img
        alt="ambulance"
        loading="lazy"
        src={ambulance}
        style={{ height: 100, margin: "30px auto 10px auto" }}
      />
    )
  }
]

export default certificationsData
