// ** React Imports
import React, { useEffect, useState } from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Third Party Imports
import Slider from "react-slick"

// ** Icons Imports
import { MdOutlinePinDrop, MdAccessTime } from "react-icons/md"
import { InstagramEmbed } from "react-social-media-embed"

function ProjectCard({
  title,
  summary,
  date,
  description,
  location,
  youtubeLinks = [],
  instagramLinks = [],
  imgPath
}) {
  // ** React Slick Settings
  var sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1
  }

  // ** States
  const [open, setOpen] = useState(false)

  // ** Hanlders
  const handleCardClick = () => {
    setOpen((prev) => !prev)
  }

  const handleInnerCardClick = (e) => {
    if (open) {
      e.stopPropagation()
    }
  }

  useEffect(() => {
    // Add or remove a class to the body element to toggle scroll behavior
    if (open) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }

    // Remove the event listener when the component unmounts
    return () => {
      document.body.classList.remove("no-scroll")
    }
  }, [open])

  return (
    <div
      className={`${styles.container} ${open ? styles.containerOpen : null}`}
      onClick={handleCardClick}
    >
      <div className={styles.card} onClick={handleInnerCardClick}>
        <img
          loading="lazy"
          src={imgPath}
          className={styles.illustration}
          alt={title}
        />
        <span className={styles.cardTitle}>{location}</span>

        {/* CARD OPEN CONTENT */}
        <div
          className={styles.openContent}
          style={{ overflowY: open && "auto", zIndex: open && 999999 }}
        >
          {/* Title */}
          <h1 className={styles.title}>{title}</h1>

          {/* Summary */}
          <div className={styles.summary}>{summary}</div>

          {/* Location */}
          <div className={styles.locationContainer}>
            <MdOutlinePinDrop size={25} />
            <span>{location}</span>
          </div>

          {/* Period */}
          <div className={styles.periodContainer}>
            <MdAccessTime size={24} />
            <span>{date}</span>
          </div>

          {/* Main Content */}
          <div className={styles.contentContainer}>
            {/* Description */}
            {open && <div className={styles.description}>{description}</div>}

            {/* Sliders */}
            <div className={styles.sliderContainer}>
              {open && (
                <>
                  <Slider {...sliderSettings}>
                    {youtubeLinks.map((video) => (
                      <div className={styles.youtubeVideoContainer}>
                        <iframe
                          style={{
                            width: "100%",
                            aspectRatio: "16/9",
                            border: "none"
                          }}
                          title="as"
                          src={video}
                        ></iframe>
                      </div>
                    ))}
                  </Slider>
                  <Slider {...sliderSettings}>
                    {instagramLinks.map((post, index) => (
                      <div>
                        <InstagramEmbed
                          url={post}
                          // width={330}
                        />
                      </div>
                    ))}
                  </Slider>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <span className={styles.name}>{title}</span>
    </div>
  )
}

export default ProjectCard
