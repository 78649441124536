// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

function TestimonialCard({ imgSrc, name, title, text }) {
  return (
    <div className={styles.container}>
      {/* header */}
      <div className={styles.header}>
        <img loading="lazy" src={imgSrc} alt={`artist-testimonial-${name}`} />
        <div className={styles.testimonialPersonInfo}>
          <span>{name}</span>
          <span>{title}</span>
        </div>
      </div>

      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default TestimonialCard
