// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./styles.module.css"

// ** Third Party Imports
import Marquee from "react-fast-marquee"

function WorkedWithBrands({ sectionId }) {
  // ** Settings for the first marquee
  const marqueeSettingsFirst = {
    direction: "left",
    speed: 30,
    gradient: true,
    gradientColor: [9, 9, 9],
    gradientWidth: "70px"
  }

  //  ** Settings for the second marquee
  const marqueeSettingsSecond = {
    direction: "right",
    speed: 30,
    gradient: true,
    gradientColor: [9, 9, 9],
    gradientWidth: "70px"
  }

  // ** Image paths
  const images = importAll(
    require.context("../../../assets/brands", false, /\.(png|jpe?g|svg|webp)$/)
  )
  const midIndex = Math.floor(images.length / 2)
  const firstImages = images.slice(0, midIndex)
  const secondImages = images.slice(midIndex)

  // ** Handlers
  function importAll(r) {
    return r.keys().map(r)
  }

  return (
    <div className={styles.container} id={sectionId}>
      <span className={styles.title}>WORK WITH 40+ BRANDS WORLDWIDE</span>

      {/* First Marquee */}
      <Marquee {...marqueeSettingsFirst} className={styles.marqueeContainer}>
        {firstImages.map((image, index) => (
          <img
            loading="lazy"
            key={index}
            src={image}
            alt={`brand-${index}`}
            className={styles.brandImage}
          />
        ))}
      </Marquee>

      {/* Second Marquee */}
      <Marquee {...marqueeSettingsSecond} className={styles.marqueeContainer}>
        {secondImages.map((image, index) => (
          <img
            loading="lazy"
            key={index}
            src={image}
            alt={`brand-${index}`}
            style={{ backgroundColor: "white", borderRadius: 5 }}
            className={styles.brandImage}
          />
        ))}
      </Marquee>
    </div>
  )
}

export default WorkedWithBrands
