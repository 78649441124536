import React from "react"

// icons
import { MdPersonOutline } from "react-icons/md"

// styles
import styles from "./about.module.css"

// components
import SectionHeader from "../../components/SectionHeader/SectionHeader"

// data
import aboutData from "../../data/about"

function About({ sectionId }) {
  return (
    <div id={sectionId}>
      <SectionHeader
        title={aboutData.title}
        badgeLabel="About"
        badgeIcon={<MdPersonOutline size={20} />}
      />

      <p className={styles.paragraph}>{aboutData.description}</p>

      {/* YOUTUBE */}
      <div className={styles.youtubeVideoContainer}>
        <iframe
          style={{
            width: "100%",
            aspectRatio: "16/9",
            border: "none"
          }}
          title="as"
          src="https://www.youtube.com/embed/I8wGwn0hrqw"
        ></iframe>
      </div>
    </div>
  )
}

export default About
