const artistsData = [
  {
    id: "bebe_rexha",
    name: "Bebe Rexha",
    img: require("../assets/artists/bebe-rexha.jpg")
  },
  {
    id: 2,
    name: "Beyoncé",
    img: require("../assets/artists/Beyonce.jpg")
  },
  {
    id: 1,
    name: "Coldplay",
    img: require("../assets/artists/Cold play_ Chris Martin.jpeg")
  },
  {
    id: 13,
    name: "Ziad Rahbani",
    img: require("../assets/artists/Ziad-Rahbani-1.jpg")
  },
  {
    id: 28,
    name: "AR Rahman",
    img: require("../assets/artists/ar-rahman.jpg.webp")
  },
  {
    id: 26,
    name: "Fayez Al Saeed",
    img: require("../assets/artists/Fayez Al Saeed.png.jpeg")
  },
  {
    id: 3,
    name: "Swedish House Mafia",
    img: require("../assets/artists/Swedish-House-Mafia-AP.jpg")
  },
  {
    name: "R3HAB",
    img: require("../assets/artists/rehab.jpeg")
  },
  {
    id: 4,
    name: "Afro Jack",
    img: require("../assets/artists/Afro Jack.jpg")
  },
  {
    id: 27,
    name: "Ibrahim Maalouf",
    img: require("../assets/artists/ibrahim maalouf.jpg")
  },
  {
    id: 18,
    name: "Dammo The Great",
    img: require("../assets/artists/Dammo the Great.jpg")
  },
  {
    id: 16,
    name: "Leon Lacey",
    img: require("../assets/artists/Leon-Lacey.jpeg")
  },
  {
    id: 30,
    name: "Ben Ben",
    img: require("../assets/artists/ben-ben.jpg.webp")
  },
  {
    id: 29,
    name: "Atif Aslam",
    img: require("../assets/artists/Atif Aslam.jpg")
  },
  {
    id: 40,
    name: "Mo Ayad",
    img: require("../assets/artists/moayad.jpg")
  },
  {
    id: 20,
    name: "Saint Levant",
    img: require("../assets/artists/SAINT-LEVANT.jpeg.webp")
  },
  {
    id: 5,
    name: "Lindsey Lohan",
    img: require("../assets/artists/Lindsey Lohan.jpeg")
  },
  {
    id: 34,
    name: "Simon Rhodes",
    img: require("../assets/artists/Simon Rhodes.png")
  },
  {
    id: 36,
    name: "Eva Reistad",
    img: require("../assets/artists/Eva Reistad.jpg.webp")
  },
  {
    id: 17,
    name: "Cosmicat",
    img: require("../assets/artists/Cosmicat.webp")
  },
  {
    id: 38,
    name: "Barry Kassab",
    img: require("../assets/artists/Barry Kassab.jpeg")
  },
  {
    id: 31,
    name: "Elia Missawer",
    img: require("../assets/artists/Elia Missawer.jpeg")
  },
  {
    id: 35,
    name: "Mayssa Karaa",
    img: require("../assets/artists/Mayssa Karaa.jpg")
  },
  {
    id: 23,
    name: "Sleiman Demian",
    img: require("../assets/artists/Sleiman Demian.jpg")
  },
  {
    id: 33,
    name: "Tarek Majdalani",
    img: require("../assets/artists/Tarek Majdalani.jpg")
  },
  {
    name: "Myriam Fares",
    img: require("../assets/artists/myriam.jpeg")
  },
  {
    id: 21,
    name: "Michel Fadel",
    img: require("../assets/artists/Michel Fadel.jpg")
  },
  {
    id: 39,
    name: "Jean Marie Riachi",
    img: require("../assets/artists/jean marie riachi.jpeg")
  },
  {
    id: 37,
    name: "Alexandre Missakian",
    img: require("../assets/artists/Alexandre Missakian.jpg")
  },
  {
    id: 15,
    name: "Anthony from Adonis",
    img: require("../assets/artists/Anthony from Adonis.jpeg")
  },
  {
    id: 8,
    name: "Anthony Touma",
    img: require("../assets/artists/anthony-touma_955123.jpg.webp")
  },
  {
    id: 14,
    name: "Rolbac",
    img: require("../assets/artists/Rolbac.jpg")
  },
  {
    id: 22,
    name: "Iyam Al Lira",
    img: require("../assets/artists/iyam el lira.jpg")
  },
  {
    id: 7,
    name: "Elissa",
    img: require("../assets/artists/Elissa.jpg")
  },
  {
    id: 11,
    name: "Assi Al Hallani",
    img: require("../assets/artists/Assi-El-Hallani.png")
  },
  {
    id: 24,
    name: "Maritta Hallani",
    img: require("../assets/artists/Maritta Hallani.jpeg")
  },
  {
    id: 32,
    name: "Elio Kallassi",
    img: require("../assets/artists/Elio Kallassi.JPG")
  },
  {
    id: 43,
    name: "Freek",
    img: require("../assets/artists/Freek.jpeg")
  },
  {
    id: 9,
    name: "Dana Hourani",
    img: require("../assets/artists/Dana hourani.jpg")
  },
  {
    id: 10,
    name: "Abeer Nehme",
    img: require("../assets/artists/Abeer_Nehmee.jpg")
  },
  {
    id: 25,
    name: "Georges Nehme",
    img: require("../assets/artists/Georges nehme.jpg")
  },
  {
    id: 12,
    name: "Aminek",
    img: require("../assets/artists/aminek.webp")
  },
  {
    id: 19,
    name: "Tania Kassis",
    img: require("../assets/artists/Tania Kassis.webp")
  },
  {
    id: 6,
    name: "Carole Samaha",
    img: require("../assets/artists/Carole-Samaha.jpg")
  }
]

export default artistsData
