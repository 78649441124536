// ** React Imports
import React from "react"

// ** Styles Imports
import styles from "./sideNavigation.module.css"

//** Helpers Imports
import { smoothScrollToSection } from "../../helpers/scrollHelpers"

// ** Icons Imports
import { LuHelpingHand } from "react-icons/lu"
import { BiBriefcase, BiWrench } from "react-icons/bi"
import { HiOutlineHome } from "react-icons/hi"
// HiOutlineMail
import { MdPersonOutline, MdOutlineChat } from "react-icons/md"
import { RiFolderMusicLine } from "react-icons/ri"

const buttons = [
  {
    key: "home",
    label: "Home",
    icon: <HiOutlineHome size={21} />,
    scrollTo: "scroll-to-introduce"
  },
  {
    key: "about",
    label: "About",
    icon: <MdPersonOutline size={24} />,
    scrollTo: "scroll-to-about"
  },
  {
    key: "resume",
    label: "Resume",
    icon: <BiBriefcase size={21} />,
    scrollTo: "scroll-to-experience"
  },
  {
    key: "services",
    label: "Services",
    icon: <LuHelpingHand size={22} />,
    scrollTo: "scroll-to-specializations"
  },
  {
    key: "skills",
    label: "Skills",
    icon: <BiWrench size={20.75} />,
    scrollTo: "scroll-to-skills"
  },
  {
    key: "testimonials",
    label: "Testimonials",
    icon: <MdOutlineChat size={22} />,
    scrollTo: "scroll-to-testimonials"
  },
  {
    key: "projects",
    label: "Projects",
    icon: <RiFolderMusicLine size={22} />,
    scrollTo: "scroll-to-projects"
  }
  // {
  //   key: "contact",
  //   label: "Contact",
  //   icon: <HiOutlineMail size={22} />,
  //   scrollTo: "scroll-to-"
  // }
]

function SideNavigation({ containerStyle, className }) {
  // ** Handlers
  const scrollTo = (id) => {
    smoothScrollToSection(id)
  }

  return (
    <div
      className={`${styles.container} ${className}`}
      style={{ ...containerStyle }}
    >
      {buttons.map((section) => (
        <div className={styles.buttonContainer}>
          <button
            id={section.key}
            className={styles.iconButton}
            onClick={() => scrollTo(section.scrollTo)}
          >
            {section.icon}
          </button>
          <span className={styles.label}>{section.label}</span>
        </div>
      ))}
    </div>
  )
}

export default SideNavigation
